<template>
<div class="banner-component">
  <div class="banner-component-box">
    <banner :page="1" where="pc-home-banner" width="100%" height="100%"/>
  </div>
  <div class="right-content">
    <img src="@/assets/images/pc_personal_3dbanner.svg" class="__img">
    <n-button color="#000000" text-color="#ffffff" class="to-design" @click="openUrl">马上去设计 ></n-button>
  </div>
</div>
</template>

<script setup>
const openUrl = () => {
 if (process.env.NODE_ENV === 'development'){
   window.open('https://test.shixianjia.com')
 }else {
   window.open('https://3d.shixianjia.com')
 }
}
</script>

<style lang="less" scoped>
.banner-component{
  @apply flex items-start;
  width: 100%;
  height: 470px;
  .banner-component-box{
    width: 1035px;
    min-height: 100%;
    height: 100%;
  }
  .right-content{
    height: 100%;
    width: 457px;
    margin-left: 20px;
    @apply flex justify-between flex-col;
    .__img{
      height: 373px;
      object-fit: cover;
      border-radius: 12px;
    }
    .to-design{
      width: 100%;
      height: 77px;
      border-radius: 12px;
      font-weight: 500;
      font-size: 24px;
    }
  }
}
@media (max-width: 1451px) {
  .banner-component{
    height: 345px;
    .banner-component-box{
      width: 765px;
    }
    .right-content{
      width: 345px;
      .__img{
        height: 267px;
      }
      .to-design{
        height: 58px;
      }
    }
  }

}
</style>